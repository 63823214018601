@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* Fonts */
@layer base{
  @font-face {
    font-family: 'GeneralSans-Variable';
    src: url('/assets/fonts/GeneralSans-Variable.woff2') format('woff2'),
         url('/assets/fonts/GeneralSans-Variable.woff') format('woff'),
         url('/assets/fonts/GeneralSans-Variable.ttf') format('truetype');
         font-weight: 200 700;
    font-display: swap;
    font-style: normal;
  }

  .markdown p, .markdown ul,.markdown h1, .markdown h2, .markdown h3{
    @apply mb-5;
  }
  .markdown h1{
    @apply text-2xl font-bold;
  }
  .markdown h2{
    @apply mt-10 text-xl font-bold;
  }
  .markdown a{
    @apply text-rose-500;
  }

}